import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Highlighter from "react-highlight-words";
import moment from 'moment';

import routes from "../../libs/routes";
import images from "../../libs/images";

import { logIn, logOut, setData as setUserData } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';
import PopupMain from "../popup/PopupMain";
import * as api from '../../libs/api';
import { regEmail,regPassword, autoHypenPhone } from '../../libs/utils'

export default function Popup01(props) {
    const { mbData, accessToken, autoLogin,mbBook } = useSelector(s => s.usersReducer);

    const { text, width, button, buttonCancel, func, setFunc, noneLoading, leave, url, seq, setList, list, naverType } = props;

    const [pwcheck, setPwcheck] = useState(""); 
    const [message, setMessage] = useState(false); 
    const [message_tx, setMessageTx] = useState(""); 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [newpw1, setNewPw1] = useState(""); 
    const [newpw2, setNewPw2] = useState(""); 

    const onOutPw = (e) => {
        setPwcheck(e.target.value);
        if(e.target.value!==''){
            setMessage(false)
        }
    }
    
    const formRefNew = useRef(null);
    
    const [mdl_tkn, SetMdlTkn] = useState("");
    const sucFunc = () => {
        if(func === 'book') {
            api.deleteDataRefresh("/book",{
                subjectSeq:seq.subjectSeq,
                memberSeq:seq.memberSeq
            },mbData).then(res=>{
                if(res?.data?.status==='SUCCESS'){
        
                    const newMbBook = mbBook.filter(val => val.subjectSeq != seq?.subjectSeq );
                    
                    dispatch(
                        setUserData({
                            key: 'mbBook',
                            value: newMbBook
                        })
                    )

                    dispatch(
                        open({
                            component: <PopupMain 
                                text='삭제되었습니다.'
                                button={'확인'}
                            />
                        })
                    );
                }
            });



        }else if(func === 'check') {
            dispatch(
                open({
                    component: <PopupMain 
                        text='삭제되었습니다.'
                        button={'확인'}
                    />
                })
            );
        }else if(func === 'login'){
            navigate(routes.login,{state:{url:url}});
            dispatch(close());
        }else if(func === 'new'){
   

            api.getData("/auth/send", {}).then(res=>{
                SetMdlTkn(res.data);
                localStorage.setItem("mdl_tkn",res.data);
                setTimeout(function(){
                    window.open("", "auth_popup", "width=430,height=640,scrollbars=yes");
                    formRefNew.current.cp_cd.value = "P08100000000";
                    formRefNew.current.mdl_tkn.value = res.data;
                    formRefNew.current.tc.value = "kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
                    formRefNew.current.action = "https://safe.ok-name.co.kr/CommonSvl"
                    formRefNew.current.target = "auth_popup";
                    formRefNew.current.submit();    
                },100)
            });

        }else if(func === 'teacher'){
            navigate("/my/info",{state:{url:url}});
            dispatch(close());
        }else if(func === 'pwchange'){
            setMessage(false);
            setMessageTx("");
            if(naverType && naverType != 'naver'){
                if(pwcheck===""){
                    setMessage(true)
                    setMessageTx("비밀번호를 입력해주세요.");
                    return;
                }
            }
            if(newpw1===""){
                setMessage(true)
                setMessageTx("새 비밀번호를 입력해주세요.");
                return;
            }
            if(newpw2===""){
                setMessage(true)
                setMessageTx("새 비밀번호를 재입력해주세요.");
                return;
            }
            if(newpw1!==newpw2){
                setMessage(true)
                setMessageTx("새 비밀번호를 확인해주세요.");
                return;
            }
            if (regPassword.test(newpw1)) {
                let changeUrl = "/member/pw";
                if(naverType && naverType == 'naver'){
                    changeUrl = "/member/naver";
                    api.getDataRefresh("/member/sns",{
                        type:"naver"
                    },mbData).then(res=>{
                        if(res && res?.data?.status == "SUCCESS"){

                        }
                    });
                }
                api.putDataRefresh(changeUrl,{
                    pw:pwcheck,
                    newPassword:newpw1,
                    newPasswordChk:newpw2,
                    newNaver:naverType,
                },{},mbData).then(res=>{
                    if(res && res?.data?.status == "SUCCESS"){       
                        dispatch(
                            open({
                                component: <PopupMain 
                                    text='변경되었습니다. 다시 로그인후 이용해주세요.'
                                    button={'확인'}
                                />
                            })
                        );
                        localStorage.removeItem("access");
                        localStorage.removeItem("remail");
                        localStorage.removeItem("page");
                        localStorage.removeItem("sendData");
                        dispatch(logOut());
                        navigate("/");
                    }else{
                        setMessage(true)
                        setMessageTx("기존 비밀번호를 확인해주세요.");
                    }
                });
            }else{
                setMessage(true)
                setMessageTx("8자리 이상 영문, 숫자, 특수문자(!@#$%^*+=-)로 입력하세요.");
                return;
            }

            
            
            
        }else if(func === 'leave'){

            if(pwcheck===""){
                setMessage(true)
                setMessageTx("비밀번호를 입력해주세요.");
            } else{
                //if(window.confirm("정말로 탈퇴하시겠습니까?")){
                    api.postDataRefresh("/member/out",{
                        account:mbData.account,
                        pw:pwcheck
                    },mbData).then(res=>{
                        if(res?.data?.status == "SUCCESS"){
                            localStorage.removeItem("access");
                            localStorage.removeItem("remail");
                            localStorage.removeItem("page");
                            localStorage.removeItem("sendData");
                            dispatch(
                                logOut()
                            );
                            dispatch(close());                            
                            navigate("/");
                            dispatch(
                                open({
                                    component: <PopupMain
                                        text={"회원 탈퇴되었습니다."}
                                        button="확인"
                                    />,
                                })
                            );
                        }else{
                            setMessage(true)
                            setMessageTx("비밀번호를 확인해주세요.");
                        }
                    });
                //}
            }
           
        }else if(func === 'comment_remove'){ 
            
            api.deleteDataRefresh("/event/comment" ,{
                id:seq,
            },mbData).then(res=>{
                console.log(res);
                if(res && res?.data?.status=="SUCCESS"){
                    dispatch(
                        open({
                            component: <PopupMain 
                                text='삭제되었습니다.'
                                button={'확인'}
                            />
                        })
                    );                    
                    setFunc(list.filter((val=>val.eventReplySeq!==seq)))
                }
            });
        }else if(func === 'naverPwchange'){
            dispatch(
                open({
                    component: <PopupMain
                        text={"변경할 비밀번호를 입력해주세요."}
                        button="비밀번호 변경하기"
                        buttonCancel="취소"
                        leave=" leave"
                        naverType="naver"
                        func="pwchange"
                    />,
                })
            );
        }
        
        else{
            //navigate(routes.login,{state:});
            dispatch(close());
        }
    }
    

    return (
        <>
            <div className={"modal_box"+(leave ? leave : '')} style={{ maxWidth: width }}>
            <   form ref={formRefNew} method="post" >
                    <input type="hidden" name="cp_cd" />
                    <input type="hidden" name="mdl_tkn"/>
                    <input type="hidden" name="tc"/>
                </form>
                <p>
                    {text}
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </p>
                {button == '탈퇴하기' ? 
                <>
                <input type="password" placeholder="비밀번호 입력" className="text_pw" onChange={(e)=>onOutPw(e)}/>
                <small className={`out_pw${message ? ' active' : ''}`}>{message_tx}</small>
                </>
                : button == '비밀번호 변경하기' ?
                <>
                {naverType == "naver" ? 
                <></>
                :
                <input type="password" placeholder="기존 비밀번호 입력" className="text_pw" onChange={(e)=>onOutPw(e)}/>
                }
                <input type="password" placeholder="새 비밀번호 입력" className="text_pw" onChange={(e)=>setNewPw1(e.target.value)}/>
                <input type="password" placeholder="새 비밀번호 재입력" className="text_pw" onChange={(e)=>setNewPw2(e.target.value)}/>
                <small className={`out_pw${message ? ' active' : ''}`}>{message_tx}</small>
                </>
                :
                <></>
                }
                <div>
                    {buttonCancel &&
                        <button type="button" onClick={() => dispatch(close())}>{buttonCancel}</button>
                    }               
                    {button && 
                        <button type="button" onClick={() => sucFunc()}>{button}</button>
                    } 
                </div>
            </div>
        </>
    );
}
