import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import images from '../../libs/images';
import routes from '../../libs/routes';
import * as api from '../../libs/api';

import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import Popup01 from '../popup/Popup01';
import PopupMain from '../../pages/popup/PopupMain';


export default function Register01(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); 
    const [agree, setAgree] = useState({agree0:"",agree1:"",agree2:""});

    const popupFunc = (type) => {
        
        if(type === 'register_terms1') {
            dispatch(
                open({
                    title: "서비스 이용약관",
                    message: agree.agree0,
                    
                })
            );
        }

        if(type === 'register_terms2') {
            dispatch(
                open({
                    title: "개인정보 수집 및 이용동의",
                    message: agree.agree1,
                    
                })
            );
        }

        if(type === 'register_terms3') {
            dispatch(
                open({
                    title: "개인 정보 제 3자 제공",
                    message: agree.agree2,
                    
                })
            );
        }
    }

    const [agreements, setAgreements] = useState({
        agreeAll: false,
        agree1: false,
        agree2: false,
        agree3: false
    });

    const [isAllAgreed, setIsAllAgreed] = useState(false);
    const [showAgreementError, setShowAgreementError] = useState(false);
    const authData = localStorage.getItem("sendData") ? JSON.parse(localStorage.getItem("sendData")) : '';
    useEffect(() => {

        api.getData("/agree", {}).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data;
                setAgree({agree0:ret.agree0,agree1:ret.agree1,agree2:ret.agree2});
            }
        });

        const allAgreed = agreements.agree1 && agreements.agree2 && agreements.agree3;
        setAgreements((prevState) => ({
            ...prevState,
            agreeAll: allAgreed
        }));
 
        setIsAllAgreed(allAgreed);


        const childMessage = (e) => {
            if (e.data.type != 'agree') {
              return
            }
            let redata = location.state;
            if( !redata){
                redata = {};
            }
            redata['agree'] = 1;

            if( e?.data?.payload?.CI && e?.data?.payload?.CI!==''){
                navigate("/register",{state:redata});
            }else{
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="본인인증을 실패하셨습니다. 다시 인증을 해주세요."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };


    }, [agreements.agree1, agreements.agree2, agreements.agree3]);

    const handleAgreeAllChange = () => {
        const newAgreeAll = !agreements.agreeAll;
        setAgreements({
            agreeAll: newAgreeAll,
            agree1: newAgreeAll,
            agree2: newAgreeAll,
            agree3: newAgreeAll
        });
    };

    const handleAgreementChange = (e) => {
        const { id, checked } = e.target;
        setAgreements((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    const handleAuthenticate = () => {
        if (!isAllAgreed) {
            setShowAgreementError(true);
            const registerTermsBoxPart2 = document.querySelector('.register_terms_box_part2');
            registerTermsBoxPart2.classList.add('shake');
            
            registerTermsBoxPart2.scrollIntoView({ behavior: 'smooth', block: 'center' });

            setTimeout(() => {
                registerTermsBoxPart2.classList.add('fade-out');
                setTimeout(() => {
                    registerTermsBoxPart2.classList.remove('shake', 'fade-out');
                }, 500);
            }, 300);
        }
    };
    
    function goUrl(url) {
        navigate(url);
        window.scrollTo(0,0);
    }

    
    const [mdl_tkn, SetMdlTkn] = useState("");

    const formRef = useRef(null);
    function onPopup1(){
        //window.open("http://61.105.41.154:3001/auth", "", "width=430,height=640,scrollbars=yes");


        api.getData("/auth/send", {}).then(res=>{
            SetMdlTkn(res.data);
            localStorage.setItem("mdl_tkn",res.data);

            setTimeout(function(){
                window.open("", "auth_popup", "width=430,height=640,scrollbars=yes");
                formRef.current.cp_cd.value = "P08100000000";
                formRef.current.mdl_tkn.value = res.data;
                formRef.current.tc.value = "kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
                formRef.current.action = "https://safe.ok-name.co.kr/CommonSvl"
                formRef.current.target = "auth_popup";
                formRef.current.submit();    
            },100)
        });
    }



    return (
        <div className="login_back">
            <div className="login_box"> 
                <form ref={formRef} method="post" >
                    <input type="hidden" name="cp_cd" />
                    <input type="hidden" name="mdl_tkn"/>
                    <input type="hidden" name="tc"/>
                </form>
                
                <div className="title_box">
                    <button type="button" onClick={()=>goUrl("/")} className="m_close" ><img src='/images/m_close.svg' /></button>
                    <p className="title">회원 가입</p>
                    <span className="sub_title">서비스 약관에 동의해 주세요.</span>
                </div>
                <hr />
                <div className="register_terms_box">
                    <div className="register_terms_box_part1">
                        <input
                            type="checkbox"
                            id="agreeAll"
                            className="agree_chk"
                            checked={agreements.agreeAll}
                            onChange={handleAgreeAllChange}
                        />
                        <div className="terms_box_sub_text">
                            <label htmlFor="agreeAll">모두 동의합니다</label>
                            <div>
                                전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. 선택항목에 대한 동의를 거부하시는 경우에도서비스 이용이 가능합니다.
                            </div>
                        </div>
                    </div>

                    <div className="register_terms_box_part2">
                        <div className="agree_chk">
                            <div className="agree_chk_sub">
                                <input
                                    type="checkbox"
                                    id="agree1"
                                    className="agree_chk"
                                    checked={agreements.agree1}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree1">[필수] 서비스이용약관</label>
                            </div>
                            <button type="button" onClick={() => { popupFunc('register_terms1') }}>보기</button>
                        </div>

                        <div className="agree_chk">
                            <div className="agree_chk_sub">
                                <input
                                    type="checkbox"
                                    id="agree2"
                                    className="agree_chk"
                                    checked={agreements.agree2}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree2">[필수] 개인정보 수집 및 이용 동의</label>
                            </div>
                            <button type="button" onClick={() => { popupFunc('register_terms2') }}>보기</button>
                        </div>

                        <div className="agree_chk">
                            <div className="agree_chk_sub">
                                <input
                                    type="checkbox"
                                    id="agree3"
                                    className="agree_chk"
                                    checked={agreements.agree3}
                                    onChange={handleAgreementChange}
                                />
                                <label htmlFor="agree3">[필수] 개인 정보 제 3자 제공</label>
                            </div>
                            <button type="button" onClick={() => { popupFunc('register_terms3') }}>보기</button>
                        </div>
                    </div>
                </div>
                {showAgreementError && <p className="error register">서비스 약관에 동의해 주세요</p>}

                <div className="sub_text">
                    <p>서비스 이용을 위해 본인인증이 필요합니다.</p>
                </div>

                <button
                    type="submit"
                    className={`login_btn none ${isAllAgreed ? 'active' : ''}`}
                    onClick={() => {
                        if (isAllAgreed) {
                            onPopup1()
                        } else {
                            handleAuthenticate();
                        }
                    }}
                >
                    본인인증
                </button>
            </div>
        </div>
    );
}